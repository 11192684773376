import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import ManageLayout from '../elements/ManageLayout'
import Sidebar from '../elements/Sidebar'
import ManageHeader from '../elements/ManageHeader'
import Dropdown from '../elements/ReactSelectDropdown'
import TextInput from '../elements/TextInput'
import ManageTable from '../elements/ManageTable'
import IconButton from '../elements/IconButton'
import Button from '../elements/Button'
import ModalManager from '../elements/ModalManager'

import EditDealersModal from '../assets/manageReserveAccounts/EditDealersModal'
import EditSubagentsModal from '../assets/manageReserveAccounts/EditSubagentsModal'
import EditAccountModal from '../assets/manageReserveAccounts/EditAccountModal'
import EditBalanceModal from '../assets/manageReserveAccounts/EditBalanceModal'
import CreateNewAccountModal from '../assets/manageReserveAccounts/CreateNewAccountModal'

import Edit from '../media/icons/icons8-edit-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Notification from '../elements/Notification'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [refresh, setRefresh] = useState(false)

    const refreshData = (skip) => {       
        if (skip !== undefined)
        {
            console.log('hmmm')
            setRefresh((refresh) => !refresh)
        }
        else
        {
            console.log('hmmm2')
            refreshAllData();
        }
    }

    function formatDate(isoString) {
        const date = new Date(isoString);
      
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
      
        return date.toLocaleString('en-US', options);
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [cookies, setCookies] = useCookies([])
    const [skip, setSkip] = useState(15)

    const [selectedAccount, setSelectedAccount]= useState()

    const [accounts, setAccounts] = useState([])

    const debounceTimerRef = useRef();

    const [checkIfRan, setCheckIfRan] = useState(false)

    useEffect(() => {
        //THIS IS NOT THE NORMAL USEEFFECT
        //this is a special useEffect specifically for refreshing data from a weekly7/active change as instead of passing the data through the table and back into this element, I can just use this instead.
        //this mirrors refreshAllData(), I cant call that method as its being called from inside the table so the skip value is not the current skip value and will always return 0 (the initally set value).
        if (checkIfRan)
        {
            let tokenValues = {
                refresh_token: cookies['refresh_token'],
                refresh_token_expires: cookies['refresh_token_expires'],
                access_token: cookies['access_token'],
                access_token_expires: cookies['access_token_expires'],
            }
    
            //get dealers
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
            myHeaders.append("Content-Type", "application/json");
        
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow',
              body: JSON.stringify({})
            };
    
            let limit = 0;
            if (skip === 0)
            {
                limit = 30
            }
            else
            {
                limit = skip;
            }
    
            props.tokenSafeAPIRequest('/account/manage/search?limit=' + limit + '&fetch_links=true&reverse_sort=true&active_only=false', requestOptions, tokenValues, (result, status) => {
                if (status === 200)
                {
    
                } else {setNotification(props.getServerResponse(result))}
                let tempReserveAccounts = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ];
                let tempReserveAccountsData = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ];
                console.log(result)
                setAccounts(result);
                result.map((account, index) => {
                    tempReserveAccounts[0].push('')
                    tempReserveAccounts[1].push(account.name)
                    tempReserveAccounts[2].push(account.dealership_owner_ID?.name)
                    tempReserveAccounts[3].push(account.user_IDs)
                    tempReserveAccounts[4].push(account?.balance)
                    tempReserveAccounts[5].push(account?.timestamp)
                    tempReserveAccounts[6].push(account.notes)
    
                    tempReserveAccountsData[0].push('')
                    tempReserveAccountsData[1].push(account.name)
                    tempReserveAccountsData[2].push(account.dealership_owner_ID?.name)
                    tempReserveAccountsData[3].push(account.user_IDs)
                    tempReserveAccountsData[4].push(account?.balance)
                    tempReserveAccountsData[5].push(account?.timestamp)
                    tempReserveAccountsData[6].push(account.notes)
                })
    
                if (searchElementValues[0] === '')
                {
                    console.log('No search detected')
                    setTableValues(tempReserveAccounts)
                    setTableData(tempReserveAccountsData)
                }
                else
                {
                    console.log('Search Detected!')
                    searchSpiffees(searchElementValues[0], limit)
                }
            })
    
            // setSkip((skip) => skip + 15)
    
        }
    }, [refresh])


    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
        })
        }

        props.tokenSafeAPIRequest('/account/manage/search?limit=30&fetch_links=true&reverse_sort=true&active_only=false', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            let tempReserveAccounts = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempReserveAccountsData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            console.log(result)
            setAccounts(result);
            result.map((account, index) => {
				tempReserveAccounts[0].push('')
                tempReserveAccounts[1].push(account.name)
                tempReserveAccounts[2].push(account.dealership_owner_ID?.name)
                tempReserveAccounts[3].push(account.user_IDs)
                tempReserveAccounts[4].push(account?.balance)
                tempReserveAccounts[5].push(account?.timestamp)
                tempReserveAccounts[6].push(account.notes)

                tempReserveAccountsData[0].push('')
                tempReserveAccountsData[1].push(account.name)
                tempReserveAccountsData[2].push(account.dealership_owner_ID?.name)
                tempReserveAccountsData[3].push(account.user_IDs)
                tempReserveAccountsData[4].push(account?.balance)
                tempReserveAccountsData[5].push(account?.timestamp)
                tempReserveAccountsData[6].push(account.notes)
            })

            console.log('qqqqqqqqqqqqqqqqqqqq' + searchElementValues[0])

            if (searchElementValues[0] === '')
            {
                console.log('No Need to search!')
                setTableValues(tempReserveAccounts)
                setTableData(tempReserveAccountsData)
            }
            else
            {
                console.log('Need to search!')
                searchSpiffees(searchElementValues[0], skip)
            }
        })

        setSkip((skip) => skip + 15)
        setCheckIfRan(true)
    }, [])

    const onBottom = () => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            name: searchElementValues[0] === '' ? null:searchElementValues[0]
        })
        }
    
        props.tokenSafeAPIRequest('/account/manage/search?limit=15&fetch_links=true&reverse_sort=true&active_only=false&skip=' + skip, requestOptions, tokenValues, (result) => {

            console.log(result)

            let tempReserveAccounts = tableValues;
            let tempReserveAccountsData = tableData

            setAccounts((accounts) => accounts.concat(result));

            result.map((account, index) => {
				tempReserveAccounts[0].push('')
                tempReserveAccounts[1].push(account.name)
                tempReserveAccounts[2].push(account.dealership_owner_ID?.name)
                tempReserveAccounts[3].push(account.user_IDs)
                tempReserveAccounts[4].push(account?.balance)
                tempReserveAccounts[5].push(account?.timestamp)
                tempReserveAccounts[6].push(account.notes)

                tempReserveAccountsData[0].push('')
                tempReserveAccountsData[1].push(account.name)
                tempReserveAccountsData[2].push(account.dealership_owner_ID?.name)
                tempReserveAccountsData[3].push(account.user_IDs)
                tempReserveAccountsData[4].push(account?.balance)
                tempReserveAccountsData[5].push(account?.timestamp)
                tempReserveAccountsData[6].push(account.notes)
            })

            setTableValues(tempReserveAccounts)
            setTableData(tempReserveAccountsData)
        })

        setSkip((skip) => skip + 15)
    }

    //makes custom request to take all current data and update it.
    const refreshAllData = (currentSkip, searchValue) => {
        console.log('Refreshing Data')
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })

        console.log(filterData)
        console.log(formattedFilters)
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            })
        }

        let tempSkip = currentSkip === undefined ? skip:currentSkip

        console.log(skip)

        props.tokenSafeAPIRequest('/account/manage/search?limit=' + tempSkip + '&fetch_links=true&reverse_sort=true&active_only=false', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            let tempReserveAccounts = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempReserveAccountsData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            console.log(result)
            setAccounts(result);
            result.map((account, index) => {
				tempReserveAccounts[0].push('')
                tempReserveAccounts[1].push(account.name)
                tempReserveAccounts[2].push(account.dealership_owner_ID?.name)
                tempReserveAccounts[3].push(account.user_IDs)
                tempReserveAccounts[4].push(account?.balance)
                tempReserveAccounts[5].push(account?.timestamp)
                tempReserveAccounts[6].push(account.notes)

                tempReserveAccountsData[0].push('')
                tempReserveAccountsData[1].push(account.name)
                tempReserveAccountsData[2].push(account.dealership_owner_ID?.name)
                tempReserveAccountsData[3].push(account.user_IDs)
                tempReserveAccountsData[4].push(account?.balance)
                tempReserveAccountsData[5].push(account?.timestamp)
                tempReserveAccountsData[6].push(account.notes)
            })

            console.log('Search Element: ' + searchElementValues[0])

            if (searchElementValues[0] === '')
            {
                console.log('No search detected')
                setTableValues(tempReserveAccounts)
                setTableData(tempReserveAccountsData)
            }
            else
            {
                console.log('Search Detected!')
                searchSpiffees(searchElementValues[0], tempSkip)
            }
        })

        setSkip((skip) => skip + 15)

    }

    const state_list = [
        {value: '', label: 'All States'},
        {value: 'AL', label: 'AL'},
        {value: 'AK', label: 'AK'},
        {value: 'AZ', label: 'AZ'},
        {value: 'AR', label: 'AR'},
        {value: 'CA', label: 'CA'},
        {value: 'CO', label: 'CO'},
        {value: 'CT', label: 'CT'},
        {value: 'DE', label: 'DE'},
        {value: 'FL', label: 'FL'},
        {value: 'GA', label: 'GA'},
        {value: 'HI', label: 'HI'},
        {value: 'ID', label: 'ID'},
        {value: 'IL', label: 'IL'},
        {value: 'IN', label: 'IN'},
        {value: 'IA', label: 'IA'},
        {value: 'KS', label: 'KS'},
        {value: 'KY', label: 'KY'},
        {value: 'LA', label: 'LA'},
        {value: 'ME', label: 'ME'},
        {value: 'MD', label: 'MD'},
        {value: 'MA', label: 'MA'},
        {value: 'MI', label: 'MI'},
        {value: 'MN', label: 'MN'},
        {value: 'MS', label: 'MS'},
        {value: 'MO', label: 'MO'},
        {value: 'MT', label: 'MT'},
        {value: 'NE', label: 'NE'},
        {value: 'NV', label: 'NV'},
        {value: 'NH', label: 'NH'},
        {value: 'NJ', label: 'NJ'},
        {value: 'NM', label: 'NM'},
        {value: 'NY', label: 'NY'},
        {value: 'NC', label: 'NC'},
        {value: 'ND', label: 'ND'},
        {value: 'OH', label: 'OH'},
        {value: 'OK', label: 'OK'},
        {value: 'OR', label: 'OR'},
        {value: 'PA', label: 'PA'},
        {value: 'RI', label: 'RI'},
        {value: 'SC', label: 'SC'},
        {value: 'SD', label: 'SD'},
        {value: 'TN', label: 'TN'},
        {value: 'TX', label: 'TX'},
        {value: 'UT', label: 'UT'},
        {value: 'VT', label: 'VT'},
        {value: 'VA', label: 'VA'},
        {value: 'WA', label: 'WA'},
        {value: 'WV', label: 'WV'},
        {value: 'WI', label: 'WI'},
        {value: 'WY', label: 'WY'},
    ];

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let tempContractList = []
        let tempDealerList = []
        let tempSubagentList = []

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
          }

        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempDealerList.push({value: '', label: 'All Dealers'})
            result.map((dealer, index) => {
                tempDealerList.push({value: [dealer.id], label: dealer.name})
            })
        })

        props.tokenSafeAPIRequest('/user/search?limit=99999&active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempSubagentList.push({value: '', label: 'All Subagents'})
            result.map((subagent, index) => {
                tempSubagentList.push({value: [subagent.id], label: subagent.name})
            })
        })

        setFilterData([
            {
                key: 'user_ID',
                options: tempSubagentList,
                selectedValue: '',
                selectedDisplay: 'Subagent'
            },
            {
                key: 'dealership_ID',
                options: tempDealerList,
                selectedValue: '',
                selectedDisplay: 'Dealership'
            },
        ])
    },[])

    const [filterData, setFilterData] = useState([
        {
            key: 'user_ID',
            options: [],
            selectedValue: '',
            selectedDisplay: 'Subagent'
        },
        {
            key: 'dealership_ID',
            options: [],
            selectedValue: '',
            selectedDisplay: 'Dealership'
        },
    ])

    const handleFilterDataChange = (index, value) => {
        setFilterData((filterData) => filterData.map((filter, index2) => {
            if (index2 === index)
            {
                filter.selectedValue = value.value[0];
                filter.selectedDisplay = value.label;
            }
            return filter;
        }))
        refreshAllData()
    }
    
    const searchElements = [
        (props) => (<div><TextInput placeholder='Search' colors={props.colors} onChange={(e) => onSearchElementUpdate(e, props.index)} prefix="" width={270} data={searchElementValues[0]}></TextInput></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(0, e)} index={props.index} indexParent={props.indexParent} data={filterData[0].options} width={195} value={filterData[0].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(1, e)} index={props.index} indexParent={props.indexParent} data={filterData[1].options} width={195} value={filterData[1].selectedDisplay}></Dropdown></div>),
    ]

    const [searchElementValues, setSearchElementValues] = useState([
        '',
    ])

    const onSearchElementUpdate = (e, indexParent) => {
        setSearchElementValues((searchElementValues) => 
            searchElementValues.map((value, index) => 
                indexParent === index ? e : value
        ));

        // Reset the debounce timer
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
            console.log('Searching Spiffees')
            searchSpiffees(e, 31, true);
        }, 1000); // 3000 ms = 3 seconds
    }

    useEffect(() => {
        return () => {
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
        };
    }, []);

    
    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Edit',
            displayElement: (props) => (<IconButton onClick={() => {setEditAccountModal(true); setSelectedAccount(props.index)}} Style={{backgroundColor: props.colors.primary}} size={31} image={Edit} colors={props.colors}></IconButton>),
            default: '',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap', maxWidth: '450px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealerships',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}><Button onClick={() => {setEditDealersModal(true); setSelectedAccount(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>Edit</Button></span>),
            default: '',
        },
        {
            title: 'Subagents',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}><Button onClick={() => {setEditSubagentsModal(true); setSelectedAccount(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>Edit</Button></span>),
            default: false,
        },
        {
            title: 'Edit Balance',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <IconButton onClick={() => {setSelectedAccount(props.index); setEditBalanceModal(true);}} Style={{backgroundColor: props.colors.primary}} size={31} image={Edit} colors={props.colors}></IconButton>
                {/* <span style={{marginLeft: '10px'}}>${props.data}</span> */}
            </div>}</span>),
            default: '',
        },
        {
            title: 'Creation Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{formatDate(props.data)}</span>),
            default: '',
        },
        {
            title: 'Note',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default], 
        [tableTitles[5].default],
        [tableTitles[6].default],
    ])

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]], 
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e, x, y) => {
        console.log(e, x, y)
    }

    const [editSpiffeeModal, setEditSpiffeeModal] = useState(false)
    const [editSubagentsModal, setEditSubagentsModal] = useState(false)
    const [createNewSpiffeeModal, setCreateNewSpiffeeModal] = useState(false)
    const [editDealersModal, setEditDealersModal] = useState(false)

    const searchSpiffees = (e, preloadedSpiffees, typedSearch) => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log('Searching By: ' + e)

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            name: e === '' ? null:e
        })
        };

        props.tokenSafeAPIRequest('/account/manage/search?limit=' + preloadedSpiffees + '&fetch_links=true&reverse_sort=true&active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)

            let tempReserveAccounts = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempReserveAccountsData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];

            setAccounts(result);

            result.map((account, index) => {
				tempReserveAccounts[0].push('')
                tempReserveAccounts[1].push(account.name)
                tempReserveAccounts[2].push(account.dealership_owner_ID?.name)
                tempReserveAccounts[3].push(account.user_IDs)
                tempReserveAccounts[4].push(account?.balance)
                tempReserveAccounts[5].push(account?.timestamp)
                tempReserveAccounts[6].push(account.notes)

                tempReserveAccountsData[0].push('')
                tempReserveAccountsData[1].push(account.name)
                tempReserveAccountsData[2].push(account.dealership_owner_ID?.name)
                tempReserveAccountsData[3].push(account.user_IDs)
                tempReserveAccountsData[4].push(account?.balance)
                tempReserveAccountsData[5].push(account?.timestamp)
                tempReserveAccountsData[6].push(account.notes)
            })

            setTableValues(tempReserveAccounts)
            setTableData(tempReserveAccountsData)
        })

        if (typedSearch)
        {
            console.log('Resetting Skip')
            setSkip(30)
        }
    }

    function setEmptyStringsToNull(obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key] === '') {
                obj[key] = null;
            }
        });
        return obj;
    }

    const addNewAccount = (accountData) => {
        console.log(accountData)
        
        accountData = setEmptyStringsToNull(accountData);

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(accountData)
        };

        props.tokenSafeAPIRequest('/account/manage', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {
                setCreateNewAccountModal(false)
                setNotification('Created Reserve Account Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }  

    const editSpiffee = (spiffeeData) => {
        console.log(spiffeeData)

        if (!spiffeeData.corporate_spiffee)
        {
            delete spiffeeData.name
        }

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(spiffeeData)
        };

        console.log(spiffeeData)

        props.tokenSafeAPIRequest('/spiffee/' + spiffeeData.id, requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {
                setEditSpiffeeModal(false)
                setNotification('Edited Spiffee Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editAccount = (accounts) => {
        console.log(accounts)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                name: accounts.name
            })
        };

        props.tokenSafeAPIRequest('/account/manage/' + accounts.id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200) 
            {
                setEditAccountModal(false)
                setNotification('Edited Reserve Account Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const deleteSpiffee = () => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/spiffee/' + accounts[selectedAccount].id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setEditSpiffeeModal(false)
                setNotification('Deleted Spiffee Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editActive = (rowIndex, tableValues, tableData, currentSkipValue, searchValue) => {

        console.log(transposeArray(tableData)[rowIndex][2])

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        };

        console.log(transposeArray(tableData)[rowIndex][6])
        console.log(currentSkipValue)

        if (transposeArray(tableData)[rowIndex][6] === 1)
        {
            console.log('Deactivating')
            props.tokenSafeAPIRequest('/spiffee/deactivate/' + transposeArray(tableData)[rowIndex][2], requestOptions, tokenValues, (result, status) => {
                console.log(result)
                if (status === 200)
                {
                    setNotification('Deactivated Spiffee Successfully at: ' + getCurrentTimeFormatted())
                    refreshData(currentSkipValue, searchValue)
                } else {setNotification(props.getServerResponse(result))}
            })
        }
        else
        {
            console.log('Activating')
            props.tokenSafeAPIRequest('/spiffee/activate/' + transposeArray(tableData)[rowIndex][2], requestOptions, tokenValues, (result, status) => {
                console.log(result)
                if (status === 200)
                {
                    setNotification('Activated Spiffee Successfully at: ' + getCurrentTimeFormatted())
                    refreshData(currentSkipValue, searchValue)
                } else {setNotification(props.getServerResponse(result))}
            })
        }
    }

    const [notification, setNotification] = useState('')

    const [editAccountModal, setEditAccountModal] = useState(false)
    const [createNewAccountModal, setCreateNewAccountModal] = useState(false)

    const deleteAccount = () => {

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/account/manage/' + accounts[selectedAccount].id, requestOptions, '', (result, status) => {
            console.log(result)
            if (status === 200) 
            {
                setEditAccountModal(false)
                setNotification('Deleted Reserve Account Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const [editBalanceModal, setEditBalanceModal] = useState(false)

    const adjustAccount = (data) => {
        console.log(data)

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                amount: data.amount,
                reason: data.reason,
            })
        };

        props.tokenSafeAPIRequest('/account/manage/adjust/' + accounts[selectedAccount].id, requestOptions, '', (result, status) => {
            console.log(result)
            if (status === 200) 
            {
                setEditBalanceModal(false)
                setNotification('Edited Reserve Account Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editDealers = (data) => {
        console.log(data)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                dealership_owner_IDs: data[1]
            })
        };

        props.tokenSafeAPIRequest('/account/manage/' + accounts[selectedAccount].id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200) 
            {
                setEditDealersModal(false)
                setNotification('Edited Reserve Account Dealers Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editSubagents = (data) => {
        console.log(data)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                user_IDs: data[1]
            })
        };

        props.tokenSafeAPIRequest('/account/manage/' + accounts[selectedAccount].id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200) 
            {
                setEditSubagentsModal(false)
                setNotification('Edited Reserve Account Subagents Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    return (
        <ManageLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <ManageHeader colors={props.colors} searchElements={searchElements} buttonText={'Add New Reserve Account'} title='Manage Reserve Accounts' onClick={() => setCreateNewAccountModal(true)} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ManageHeader>
            <ManageTable colors={props.colors} tableData={tableValues} tableIDs={tableData} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} onBottom={onBottom} selectMenu={false} skip={skip} searchElement={searchElementValues[0]}></ManageTable>
            <ModalManager colors={props.colors}>
                <EditDealersModal colors={props.colors} isOpen={editDealersModal} setIsOpen={() => setEditDealersModal(false)} editDealers={editDealers} tableData={tableData} tableValues={tableValues} selectedAccount={selectedAccount} accounts={accounts} onDelete={deleteSpiffee} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></EditDealersModal>
                <EditSubagentsModal colors={props.colors} isOpen={editSubagentsModal} setIsOpen={() => setEditSubagentsModal(false)} editSubagents={editSubagents}  accounts={accounts} selectedAccount={selectedAccount} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></EditSubagentsModal>
                <CreateNewAccountModal colors={props.colors} isOpen={createNewAccountModal} setIsOpen={() => setCreateNewAccountModal(false)} addNewAccount={addNewAccount}></CreateNewAccountModal>
                <EditAccountModal colors={props.colors} isOpen={editAccountModal} setIsOpen={() => setEditAccountModal(false)} editAccount={editAccount} deleteAccount={deleteAccount} selectedAccount={selectedAccount} tableData={tableData} tableValues={tableValues} tokenSafeAPIRequest={props.tokenSafeAPIRequest} accounts={accounts}></EditAccountModal>
                <EditBalanceModal colors={props.colors} isOpen={editBalanceModal} setIsOpen={() => setEditBalanceModal(false)} adjustAccount={adjustAccount} accounts={accounts} selectedAccount={selectedAccount} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></EditBalanceModal>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </ManageLayout>
    )
}

export default Main
