import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Counter from '../../elements/Counter.jsx'
import IconButton from '../../elements/IconButton.jsx'

import Dropdown from '../../elements/ReactSelectDropdown.jsx'
import Button from '../../elements/Button.jsx'

import Save from '../../media/icons/icons8-save-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const allReports = [
        "CombinedSubagentReport",
        "ReportingHistory",
        "NewDealerSignups",
        "SpiffeesReport",
        // "SubagentNetInsideSales",
        // "OutstandingPrespiffs",
        // "GiftCardList",
        // "RecentAddressChanges",
        "ReserveAccounts",
        // "SubagentBusiness",
        "ContractCountReport",
        "CombinedDealerReport",
        "SaturdayReport",
        "InsideSalesReport",
        "ReserveAccountTotals",
        "ListOfDPPP/GAP",
        "ListOfAdjustmentReports",
        "ListOfExpenseReports",
        "ReserveAccountWeeks",
        "VoidAndNeedsToBeReplaced",
        // "ReserveAccountReport",
        // "SubagentWeeklyChecks",
    ]

    const allLinks = [
        "Commission Received Reports",
        "Thursday Verification Reports",
        "Saturday Reports",
        "Reserve Release Request",
        "Adjustment Reports",
        "Prespiff Report",
        "Holiday Card List",
        "Reserve View Request",
        "Dealer Search",
        "Expense Reports",
        "User Settings",
        "Transient Claims",
        "Approve Reserve Release",
        "Approve View Reserve Totals",
        ">50% Spiff Reports",
        "Verification Reports (OS)",
        "Saturday Reports (OS)",
        "Commission Receieved Reports (OS)",
        "Adjustment Reports (OS)",
        "Pre-spiff Reports (OS)",
        "Expense Reports (OS)",
        "Manage Subagents",
        "Manage Dealers",
        "Manage Admins",
        "Manage Spiffees",
        "Manage Office Staff",
        "Manage MGMT",
        "Manage Reserve Accounts",
        "Permissions",
        "Edit Giftcard Fees",
    ] 

    const [agentList, setAgentList] = useState([])
    const [selectedAgent, setSelectedAgent] = useState({id: '', name: ''})
    const [userPermissions, setUserPermissions] = useState([])
    const [permissionList, setPermissionsList] = useState([])

    console.log(props)

    function updateValuesWithPartialKeys(allKeysObject, partialKeysObject) {
        // Create a new object to avoid mutating the original allKeysObject
        let updatedObject = {...allKeysObject};
      
        // Iterate over the keys in partialKeysObject
        Object.keys(partialKeysObject).forEach(key => {
          // Check if the current key from partialKeysObject exists in allKeysObject
          if (updatedObject.hasOwnProperty(key)) {
            // Update the value in updatedObject with the value from partialKeysObject
            updatedObject[key] = partialKeysObject[key];
          }
        });
      
        return updatedObject;
    }

    const loadUserPermissions = (id) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + cookies['access_token']);

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/types', requestOptions, tokenValues, (result, status2) => {
            let tempPermList = (result.reduce((accum, val) => {
                accum[val] = []
                return accum;
            }, {}))
            props.tokenSafeAPIRequest('/permission/' + id, requestOptions, tokenValues, (result2, status) => {
                console.log(result2)
                console.log(updateValuesWithPartialKeys(tempPermList, result2.allow))
                setUserPermissions(updateValuesWithPartialKeys(tempPermList, result2.allow))
            })
        })  
    }

    useEffect(() => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + cookies['access_token']);

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var raw = JSON.stringify({});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/user/search?limit=9999999', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            let dealers = []
            result.map((agent, index) => {
                dealers.push({value: agent.id, label: agent.first_name + " " + agent.last_name})
            })
            console.log(dealers)
            setAgentList(dealers)
        })
    },[])

    const editPermission = (key, permission, state) => {
        console.log(key, permission, state)
        console.log(userPermissions)
        let tempUserPermissions = {...userPermissions};
        if (state)
        {
            tempUserPermissions[key].push(permission)
        }
        else
        {
            tempUserPermissions[key] = tempUserPermissions[key].filter(item => item !== permission)
        }
        setUserPermissions(tempUserPermissions)
    }

    console.log(props)

    return (
        <div style={{borderRight: '1px solid ' + props.colors.border, paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px', display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{height: '100px', width: 'auto', maxWidth: '250px', display: 'flex', flexDirection: 'column'}}>
                    <span onClick={() => console.log(userPermissions)} style={{fontWeight: '600',}}>Select User</span>
                    <Dropdown colors={props.colors} Style={{marginTop: '10px'}} width={250} onChange={(e) => {setSelectedAgent({id: e.value, name: e.label}); loadUserPermissions(e.value)}} data={agentList} value={selectedAgent.name}>test</Dropdown>
                </div>
                <Button onClick={() => {props.savePermissions(userPermissions, selectedAgent.id); loadUserPermissions(selectedAgent.id)}} colors={props.colors} active={true} Style={{height: '35px', marginLeft: '20px', marginTop: '35px'}}>Save Permissions</Button>
                <Button onClick={() => {props.resetPermissionsToRole(selectedAgent.id); loadUserPermissions(selectedAgent.id)}} colors={props.colors} active={true} Style={{height: '35px', marginLeft: '20px', marginTop: '35px'}}>Reset to Role Permissions</Button>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', overflow: 'scroll'}}>
                {Object.entries(userPermissions).sort((a, b) => a[0].localeCompare(b[0])).map(([key, value]) => { 
                    return <div style={{display: 'flex', flexDirection: 'column', flexBasis: '24%', margin: '5px', height: '133px'}}>
                    <span style={{fontWeight: '500'}}>{key}</span>

                    {(key === 'Metrics' ? 
                    <div style={{backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, padding: '5px', borderRadius: '5px', overflowY: 'scroll'}}>
                        {allReports.map((perm, index) => {
                            return <div onClick={() => editPermission(key, perm, !value.includes(perm))} style={{display: 'flex', flexDirection: 'row'}}>
                                <span style={{marginRight: 'auto'}}>{perm}</span>
                                <div style={{cursor: 'pointer', marginRight: '10px', width: '20px', height: '20px', border: value.includes(perm) ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: value.includes(perm) ? props.colors.primary:''}}></div>         
                            </div>
                        })}
                    </div>
                    :'')}

                    {(key === 'Quicklinks' ? 
                    <div style={{backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, padding: '5px', borderRadius: '5px', overflowY: 'scroll'}}>
                        {allLinks.map((perm, index) => {
                            return <div onClick={() => editPermission(key, perm, !value.includes(perm))} style={{display: 'flex', flexDirection: 'row'}}>
                                <span style={{marginRight: 'auto'}}>{perm}</span>
                                <div style={{cursor: 'pointer', marginRight: '10px', width: '20px', height: '20px', border: value.includes(perm) ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: value.includes(perm) ? props.colors.primary:''}}></div>         
                            </div>
                        })}
                    </div>
                    :'')}
                    
                    {(key !== 'Metrics' && key !== 'Quicklinks' ? 
                    <div style={{backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, padding: '5px', borderRadius: '5px'}}>
                        <div onClick={() => editPermission(key, 'View', !value.includes('View'))} style={{display: 'flex', flexDirection: 'row'}}>
                            <span style={{marginRight: 'auto'}}>View</span>
                            <div style={{cursor: 'pointer', marginRight: '10px', width: '20px', height: '20px', border: value.includes('View') ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: value.includes('View') ? props.colors.primary:''}}></div>         
                        </div>
                        <div onClick={() => editPermission(key, 'Edit', !value.includes('Edit'))} style={{display: 'flex', flexDirection: 'row'}}>
                            <span style={{marginRight: 'auto'}}>Edit</span>
                            <div style={{cursor: 'pointer', marginRight: '10px', width: '20px', height: '20px', border: value.includes('Edit') ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: value.includes('Edit') ? props.colors.primary:''}}></div>
                        </div>
                        <div onClick={() => editPermission(key, 'Create', !value.includes('Create'))} style={{display: 'flex', flexDirection: 'row'}}>
                            <span style={{marginRight: 'auto'}}>Create</span>
                            <div style={{cursor: 'pointer', marginRight: '10px', width: '20px', height: '20px', border: value.includes('Create') ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: value.includes('Create') ? props.colors.primary:''}}></div>
                        </div>
                        <div onClick={() => editPermission(key, 'Delete', !value.includes('Delete'))} style={{display: 'flex', flexDirection: 'row'}}>
                            <span style={{marginRight: 'auto'}}>Delete</span>
                            <div style={{cursor: 'pointer', marginRight: '10px', width: '20px', height: '20px', border: value.includes('Delete') ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: value.includes('Delete') ? props.colors.primary:''}}></div>
                        </div>
                    </div>
                    :'')}
                </div>
                })}
            </div>
        </div>
    )
}

export default Main
