import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayoutColumn from '../elements/OutputLayoutColumn'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'

import ModalManager from '../elements/ModalManager'

import Dropdown from '../elements/ReactSelectDropdown'

import FiltersModal from '../assets/metrics/FiltersModal'

import { useCookies } from 'react-cookie'

import CombinedSubagentMetricExportData from '../assets/metrics/CombinedSubagentMetricExportData'

import ViewSaturdayReport from '../assets/metrics/ViewSaturdayReport'

import CalendarModal from '../assets/metrics/CalendarModal'

const Main = (props) => {

    const list = ['Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski']

    const [cookies, setCookies] = useCookies([])

    const [headerValues, setHeaderValues] = useState([])
    const [allAgents, setAllAgents] = useState([])
    const [selectedAgent, setSelectedAgent] = useState({value: '', label: 'All Agents'})
    const [selectedReport, setSelectedReport] = useState('')
    const [specificAgent, setSpecificAgent] = useState(-1)
    const [agentNumber, setAgentNumber] = useState(0)
    const [skip, setSkip] = useState(0)

    const [filtersModal, setFiltersModal] = useState(false)
    const [sort, setSort] = useState('timestamp')

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const formatDateFilters = (date) => {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString(); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${year}-${month}-${day}`;
    }

    function getRecentTuesdayAndUpcomingMonday() {
        const today = new Date();
        const recentTuesday = new Date(today);
        const upcomingMonday = new Date(today);
    
        // Adjust recentTuesday to the most recent Tuesday or today if today is Tuesday
        while (recentTuesday.getDay() !== 2) {
            recentTuesday.setDate(recentTuesday.getDate() - 1);
        }
    
        // Adjust upcomingMonday to the next Monday if today is not Monday, or today if today is Monday
        while (upcomingMonday.getDay() !== 1) {
            upcomingMonday.setDate(upcomingMonday.getDate() + 1);
        }
    
        const formatDate = (date) => {
            return date.getFullYear().toString() + '-' +
                   (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
                   date.getDate().toString().padStart(2, '0');
        };
    
        return [formatDate(upcomingMonday), formatDate(recentTuesday)];
    }

    const [calStartDate, setCalStartDate] = useState(getRecentTuesdayAndUpcomingMonday()[1])
    const [calEndDate, setCalEndDate] = useState(getRecentTuesdayAndUpcomingMonday()[0])

    //
    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
          }

        let tempDealerList = []
        let tempAdminList = []
        let tempContractList = []
        let tempSubagentList = []
        
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({subagent: true})
          }

        props.tokenSafeAPIRequest('/user/search?limit=99999', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.map((subagent, index) => {
                tempSubagentList.push({value: subagent.id, label: subagent.name})
            })
            setAllAgents(tempSubagentList)
        })

    },[])

    useEffect(() => {

    },[])

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const countDuplicateSubarrays = (arrayOfArrays) => {
        const arrayMap = new Map();
      
        arrayOfArrays.forEach(subArray => {
          // Convert each subarray to a string to use as a unique key for comparison
          const key = JSON.stringify(subArray);
          if (arrayMap.has(key)) {
            arrayMap.set(key, arrayMap.get(key) + 1);
          } else {
            arrayMap.set(key, 1);
          }
        });
      
        // Convert the map back into an array of arrays with quantities
        return Array.from(arrayMap, ([key, value]) => [JSON.parse(key), value]);
    }

    function formatDate(date) {
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
    ]

    const [totalAgents, setTotalAgents] = useState(5000)

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/user/subagent/count', requestOptions, '', (result) => {
            setTotalAgents(result-1)
        })
    },[])

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + cookies['access_token']);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    //Summary Table

    const [summaryTitles, setSummaryTitles] = useState([
        {
            title: 'Admin',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Report Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Net',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'Contract Count',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{formatNumber(props.data)}</span>),
            default: '',
        },
    ])

    const [summaryValues, setSummaryValues] = useState([
        [summaryTitles[0].default], 
        [summaryTitles[1].default],
        [summaryTitles[2].default],
        [summaryTitles[3].default], 
    ])

    //End Summary Table

    const [reportTitles, setReportTitles] = useState([
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Gross/c',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'Spiffs/c',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'Net/c',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'CC/c',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'QTY',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Total Net',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'Total CC',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'View Full Report',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}><Button onClick={() => viewFullReport(props.tableIndex, props.index, props.tableData)} Style={{borderRadius: '5px'}} colors={props.colors}>View</Button></span>),
            default: '',
        },
    ])

    const [reportValues, setReportValues] = useState([[
        [reportTitles[0].default], 
        [reportTitles[1].default],
        [reportTitles[2].default],
        [reportTitles[3].default], 
        [reportTitles[4].default], 
        [reportTitles[5].default],
        [reportTitles[6].default],
        [reportTitles[7].default], 
        [reportTitles[8].default], 
        [reportTitles[9].default],
    ]])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    useEffect(() => {
        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json")
            
        var requestOptions = specificAgent === -1 ? {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        }:{
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                subagent_ID: specificAgent
            })
        }

        props.tokenSafeAPIRequest('/metrics/combined_subagent_report?limit=1&skip=' + agentNumber + '&fetch_links=true&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, '', (result, status) => {
            console.log(result)
            setSelectedAgent(result[0])

            if (result[0].admin_report_info.length === 0)
            {
                setReportValues([[
                    [reportTitles[0].default], 
                    [reportTitles[1].default],
                    [reportTitles[2].default],
                    [reportTitles[3].default], 
                    [reportTitles[4].default], 
                    [reportTitles[5].default],
                    [reportTitles[6].default],
                    [reportTitles[7].default], 
                    [reportTitles[8].default], 
                    [reportTitles[9].default],
                ]])

                setSummaryValues([
                    [summaryTitles[0].default], 
                    [summaryTitles[1].default],
                    [summaryTitles[2].default],
                    [summaryTitles[3].default], 
                ])
            }
            else
            {

                let tempData = [
                    [],
                    [],
                    [],
                    [],
                ]

                let tempTableReportData = []

                result[0].admin_report_info.map((admin) => {
                    tempData[0].push(admin?.admin_ID?.name)
                    tempData[1].push(admin.report_subtype)
                    tempData[2].push(admin.total_net)
                    tempData[3].push(admin.total_contract_count)
                    let tempReportData = [
                        [],
                        [],
                        [],
                        [],
                        [],
                        [],
                        [],
                        [],
                        [],
                        [],
                    ]
                    admin.reports.map((report) => {
                        tempReportData[0].push(report?.dealership_ID?.name)
                        tempReportData[1].push(admin.report_subtype === 'Adjustment' ? report.report_type:report.contract_type + ' - ' + report.contract_subtype)
                        tempReportData[2].push(report.contract_gross)
                        tempReportData[3].push(report.total_spiffee_payout)
                        tempReportData[4].push(report.net)
                        tempReportData[5].push(report.total_contract_count)
                        tempReportData[6].push(report.quantity)
                        tempReportData[7].push(report.net * report.quantity)
                        tempReportData[8].push(report.total_contract_count * report.quantity)
                        tempReportData[9].push(report.code)
                    })
                    tempTableReportData.push(tempReportData)
                })
                console.log(tempTableReportData)

                tempData[0].push('Total')
                tempData[1].push('')
                tempData[2].push(result[0].total_net)
                tempData[3].push(result[0].total_contract_count)

                setReportValues(tempTableReportData)
                setSummaryValues(tempData)

            }
        })
    },[agentNumber, specificAgent, calStartDate, calEndDate])

    useEffect(() => {
        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({subagent: true})
        }

        let tempSubagentList = []

        props.tokenSafeAPIRequest('/user/search?limit=99999', requestOptions, '', (result) => {
            console.log(result)
            result.map((subagent, index) => {
                tempSubagentList.push({value: subagent.id, label: subagent.name})
            })
            setAllAgents(tempSubagentList)
        })
    },[])

    const handleAgentChange = (change) => {
        setSpecificAgent(-1)
        let tempNum = agentNumber + change;
        console.log(tempNum)
        if (tempNum < 0)
        {
            setAgentNumber(totalAgents)
        }
        else if (tempNum > totalAgents)
        {
            setAgentNumber(0)
        }
        else
        {
            setAgentNumber(tempNum)
        }
    }

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    const viewFullReport = (table, index, tableData) => {
        let reportCode = transposeArray(tableData)[index][9]

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/report/full/' + reportCode, requestOptions, '', (result) => {
            console.log(result)
            setSelectedReport(result)
            setViewSatReport(true)
        })
    }

    const [viewSatReport, setViewSatReport] = useState(false)

    const exportKeys = [{id: 'quantity', label: 'Dealer'}, {id: 'admin_ID.name', label: 'Admin'}, {id: 'dealership_ID.name', label: 'Dealership'}, {id: 'report_type', label: 'Report Type'}, {id: 'contract_type', label: 'Contract Type'}, {id: 'timestamp', label: 'Date'}, {id: 'contract_gross', label: 'Gross'}, {id: 'net', label: 'Net'}]

    const buildFilterData = () => {
        return {
            subagent_ID: specificAgent
        }
    }

    function formatDateString(dateString) {
        // Parse the date string into a Date object
        const date = new Date(dateString);
    
        // Options for toLocaleDateString to format the date as 'Month Day, Year'
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
    
        // Format the date
        return date.toLocaleDateString('en-US', options);
    }

    const [selectDatesModal, setSelectDatesModal] = useState(false)

    const filterDate = (start, end) => {
        if (start.getTime() > end.getTime())
        {
            
        }
        else
        {
            start = formatDateFilters(start)
            end = formatDateFilters(end)
            setCalStartDate(start)
            setCalEndDate(end)
        }
    }
    
    return (
        <OutputLayoutColumn colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <div></div>{/* <OutputColumn colors={props.colors} list={list} title={"Subagents"}></OutputColumn> */}
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div style={{backgroundColor: props.colors.block, border: '1px solid' + props.colors.border, padding: '5px 10px 5px 10px', borderRadius: '3px', fontWeight: '500'}}>
                        <span>Agent - {selectedAgent.subagent_code} - {selectedAgent.subagent_name} - Summary</span>
                        <span style={{marginLeft: '200px'}}>W/E: {formatDateString(selectedAgent.end_date)}</span>
                    </div>
                    <Button colors={props.colors} onClick={() => setSelectDatesModal(true)} active={true} Style={{marginLeft: 'auto', marginRight: '20px'}}>Select Week</Button>
                    {localStorage.getItem('role') === 'subagent' ? '':<div style={{backgroundColor: props.colors.block, display: 'flex', flexDirection: 'row', marginRight: '50px', alignItems: 'center', border: '1px solid ' + props.colors.border, borderRadius: '5px'}}>
                        <span className='noSelect' onClick={() => handleAgentChange(-1)} style={{fontWeight: '800', marginLeft: '10px', cursor: 'pointer'}}>{'<'}</span>
                        <Dropdown colors={props.colors} Style={{border: 'none'}} onChange={(e) => setSpecificAgent(e.value)} value={selectedAgent.subagent_name} data={allAgents}></Dropdown>
                        <span className='noSelect' onClick={() => handleAgentChange(1)} style={{fontWeight: '800', marginRight: '10px', cursor: 'pointer'}}>{'>'}</span>
                    </div>}
                </div>
                <div style={{flexGrow: 2, overflowY: 'auto'}}>
                    <OutputTable colors={props.colors} tableData={summaryValues} tableInfo={summaryTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={() => console.log('')} maxHeight='200px'></OutputTable>
                    {'admin_report_info' in selectedAgent ? selectedAgent.admin_report_info.map((admin, index) => {
                        return <div style={{display: 'flex', flexDirection: 'column', marginTop: '15px'}}>
                        <div style={{marginTop: '10px', flexDirection: 'column', display: 'flex'}}>
                            <div style={{backgroundColor: props.colors.block, border: '1px solid' + props.colors.border, padding: '5px 10px 5px 10px', borderRadius: '3px', fontWeight: '500', width: 'max-content'}}>
                                <span>{admin?.admin_ID?.name} - {admin.report_subtype}</span>
                            </div>
                            <div style={{border: '1px solid' + props.colors.border, borderRadius: '3px', fontWeight: '500', width: 'max-content', display: 'flex', flexDirection: 'row', marginTop: '-1px'}}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span style={{backgroundColor: props.colors.block, borderBottom: '1px solid ' + props.colors.border, padding: '5px 40px 5px 10px'}}>Gross</span>
                                    <span style={{padding: '5px 40px 5px 10px'}}>${formatNumber(admin.total_contract_gross)}</span>
                                </div>  
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span style={{backgroundColor: props.colors.block, borderBottom: '1px solid ' + props.colors.border, padding: '5px 40px 5px 40px'}}>Spiffs</span>
                                    <span style={{padding: '5px 40px 5px 40px'}}>${formatNumber(admin.total_spiffee_payout)}</span>
                                </div>  
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span style={{backgroundColor: props.colors.block, borderBottom: '1px solid ' + props.colors.border, padding: '5px 40px 5px 40px'}}>Net</span>
                                    <span style={{padding: '5px 40px 5px 40px'}}>${formatNumber(admin.total_net)}</span>
                                </div>  
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span style={{backgroundColor: props.colors.block, borderBottom: '1px solid ' + props.colors.border, padding: '5px 40px 5px 40px'}}>Contract Count</span>
                                    <span style={{padding: '5px 40px 5px 40px'}}>{formatNumber(admin.total_contract_count)}</span>
                                </div>  
                            </div>
                        </div>
                        <div style={{marginBottom: '20px'}}>
                            <OutputTable colors={props.colors} keys={generateKey()} index={index} tableData={reportValues[index]} tableInfo={reportTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={() => console.log('')}></OutputTable>
                        </div>
                    </div>
                    }):''}
                </div>
            </div>
            <ModalManager colors={props.colors}>
                <CombinedSubagentMetricExportData colors={props.colors} isOpen={exportDataModal} setIsOpen={() => setExportDataModal(false)} url={'/metrics/dealership'} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'Combined Subagent Report'} reportData={reportValues} admin_report_info={selectedAgent.admin_report_info}></CombinedSubagentMetricExportData>
                <ViewSaturdayReport colors={props.colors} isOpen={viewSatReport} setIsOpen={() => setViewSatReport(false)} filterData={buildFilterData()} selectedReport={selectedReport}></ViewSaturdayReport>
                <CalendarModal colors={props.colors} isOpen={selectDatesModal} setIsOpen={() => setSelectDatesModal(false)} filterDate={filterDate}></CalendarModal>
            </ModalManager>
        </OutputLayoutColumn>
    )
}

export default Main
