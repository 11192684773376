import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    console.log(props)

    const [data, setData] = useState({
        name: props.accounts[props.selectedAccount].name,
        id: props.accounts[props.selectedAccount].id,
    });

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    const [dealers, setDealers] = useState([])

    useEffect(() => {

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        }

        let tempDealerList = []
        let tempAdminList = []
        let tempContractList = []

        //get dealerships
        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempDealerList.push({value: '', label: 'All Dealers'})
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
            console.log(tempDealerList)
            setDealers(tempDealerList)
        })
    },[])


    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Reserve Account</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <IconButton colors={props.colors} onClick={() => props.deleteAccount(data.id)} size={'25px'} Style={{backgroundColor: props.colors.warning, padding: '3px', borderRadius: '5px', marginTop: '10px'}} image={Delete}></IconButton>
                <span style={{fontWeight: '600', marginTop: '5px', marginLeft: '10px'}}>Delete Account</span>
            </div>
            <div style={{flexBasis: '80%', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'column', marginRight: '10px', marginTop: '20px'}}>
                    <span style={{fontWeight: '500'}}>Name</span>
                    <TextInput colors={props.colors} Style={{marginTop: '10px'}} onChange={(e) => onDataChange(e, 'name')} data={data.name} prefix=''></TextInput>
                </div>
                <Button onClick={() => props.editAccount(data)} colors={props.colors} active={true} Style={{marginTop: '30px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
