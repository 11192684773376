import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import MainLayout from '../elements/MainLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'
import Save from '../media/icons/icons8-save-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Button from '../elements/Button.jsx'
import Counter from '../elements/Counter.jsx'
import TextInput from '../elements/TextInput.jsx'
import Table from '../elements/Table.jsx'
import IconButton from '../elements/IconButton.jsx'
import Dropdown from '../elements/ReactSelectDropdown.jsx'

import ManageTemplate from '../assets/thursdayreporting/ManageTemplates.jsx'
import SaveTemplate from '../assets/thursdayreporting/SaveTemplate.jsx'
import SubmitReport from '../assets/commissionreceivedos/SubmitReport.jsx'

import Notification from '../elements/Notification.jsx'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const buttonList = [
        {
            name: 'New Report',
            link: '',
            onClick: () => {addRow()},
        },
        {
            name: 'Submit Selected Reports',
            link: '',
            onClick: () => {submitSelectedReports()},
        },
        {
            name: 'Pending Reports',
            link: '/reports/adjustmentreportingospending',
            onClick: () => {},
        },
        // {
        //     name: 'Show Table Data',
        //     link: '',
        //     onClick: () => {console.log(tableData)},
        // },
    ]

    const headerData = [
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: 7,
            total: 21750,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: 5,
            total: 12275,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: 12,
            total: 24.5,
        },
        {
            image: Contract,
            title: 'Monthly Contracts',
            change: -3,
            total: 123,
        },
    ]

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<Counter allowNegative={false} onChange={props.onChange} indexParent={props.indexParent} colors={props.colors} index={props.index}>{props.data}</Counter>),
            default: 0,
        },
        {
            title: 'Report Type',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={reportTypes} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Subagent',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Admin',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Contract Count',
            displayElement: (props) => (<TextInput prefix='' onChange={props.onChange} index={props.index} indexParent={props.indexParent} colors={props.colors} data={props.data} width={100}></TextInput>),
            default: 0,
        },
        {
            title: 'Net',
            displayElement: (props) => (<TextInput prefix='$' onChange={props.onChange} index={props.index} indexParent={props.indexParent} colors={props.colors} data={props.data} width={100}></TextInput>),
            default: 0,
        },
        {
            title: 'Inside Sales',
            displayElement: (props) => (<div onClick={() => {onTableChange(props.data === 0 ? 1:0, props.index, props.indexParent, props.tableData, props.tableIDs)}} style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === 1 ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === 1  ? props.colors.primary:'', marginTop: '4px'}}></div>),
            default: 0,
        },
        {
            title: 'Reason',
            displayElement: (props) => (<TextInput prefix='' onChange={props.onChange} index={props.index} indexParent={props.indexParent} colors={props.colors} data={props.data} width={250}></TextInput>),
            default: '',
        },
        {
            title: 'Delete',
            displayElement: (props) => (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <IconButton onClick={() => deleteRow(props.index)} Style={{backgroundColor: props.colors.warning, marginLeft: '10px', marginRight: '10px'}} size={27} image={Delete} colors={props.colors}></IconButton>
                </div>),
            default: undefined,
        },
    ])

    const templates = [
        {
            id: 0,
            name: "Template 1",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 200,
            value: 'Template 1',
            label: 'Template 1',
        },
        {
            id: 1,
            name: "Template 2",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 300,
            value: 'Template 2',
            label: 'Template 2',
        },
        {
            id: 2,
            name: "Template 3",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 400,
            value: 'Template 3',
            label: 'Template 3',
        },
        {
            id: 3,
            name: "Template 4",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 500,
            value: 'Template 4',
            label: 'Template 4',
        },
        {
            id: 4,
            name: "Template 5",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 2600,
            value: 'Template 5',
            label: 'Template 5',
        },
    ]

    const addRow = () => {
        console.log('Add Row!')
        setTableValues((tableValues) => tableValues.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
        setRowSelected((rowSelected) => [...rowSelected, false]);
        setDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setTableData((tableData) => tableData.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
    }

    const deleteRow = (index) => {
        console.log('Deleted Row: ' + index)
        setTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
        setRowSelected((rowSelected) => [...rowSelected.slice(0, index), ...rowSelected.slice(index + 1)])
        setDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, index), ...data.slice(index + 1)];
        }))
        setTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
    }

    const onTableChange = (e, y, x) => {
        console.log('Table Updated', e, x, y)
        if (x === 1) //check what column the change was in, 1 in this case is the template column
        {
            templates.map((template) => {
                if (template.name === e.label)
                {
                    // applyTemplate(template, y)
                }
            })
        }
        if (x === 2)
        {
            // loadAdminsSubagent(e.value, y)
            // loadContractTypes(e.value, y)
            onTableChange({value: '', label: ''}, y, 3)
            onTableChange({value: '', label: ''}, y, 4)
            onTableChange({value: '', label: ''}, y, 5)
            loadDealers(e.value, y)
            // loadSubagentsList(e.value, y)
        }
        if (x === 3)
        {
            if (e.value === '')
            {

            }
            else
            {
                loadAdminsSubagent(e.value, y)
                loadContractTypes(e.value, y)
            }
        }
        setTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (x === 2)
                    {
                        return e.value;
                    }
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const applyTemplate = (template, row) => {
        setTableValues((tableValues) => tableValues.map((column, indexX) => {
            if (indexX === 2) {column[row] = template.dealer}
            if (indexX === 3) {column[row] = template.admin}
            if (indexX === 4) {column[row] = template.contractType}
            if (indexX === 5) {column[row] = template.grossPerContract}
            return column;
        }))
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const submitSelectedReports = () => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            transposeArray(tableData)
                .filter((_, index) => rowSelected[index]) // Filter out unselected rows
                .map((report) => { console.log(report)
                    return {
                        "quantity": report[0],
                        "report_type": report[1],
                        "subagent_ID": report[2],
                        "admin_ID": report[4] === '' ? null:report[4],
                        "contract_type": report[5]?.type,
                        "contract_subtype": report[5]?.subType,
                        "dealership_ID": report[3] === '' ? null:report[3],
                        "date": new Date(),
                        "total_contract_count": report[6],
                        "net": report[7] === 0 ? false:report[7],
                        "inside_sales": report[8],
                        "reason": report[9]
                    };
                })
        );

        console.log(raw)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: raw
        };

          props.tokenSafeAPIRequest('/report/adjustment', requestOptions, tokenValues, (result, status) => {
            console.log(result, status)
            if (status === 200)
            {
                setNotification('Submitted Successful: ' + getCurrentTimeFormatted())
                let deleted = 0;
                rowSelected.map((selected, index) => {
                    if (selected)
                    {
                        deleteRow(index - deleted);
                        deleted++;
                    }
                })
            }
            else
            {
                setNotification(props.getServerResponse(result))
            }
        })
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const [rowSelected, setRowSelected] = useState([false])

    const [notification, setNotification] = useState('')

    const [manageTemplateModal, setManageTemplateModal] = useState(false)
    const [manageSaveTemplateModal, setManageSaveTemplateModal] = useState(false)

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default], 
        [tableTitles[2].default], 
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
        [tableTitles[7].default], 
        [tableTitles[8].default], 
        [tableTitles[9].default], 
        [tableTitles[10].default], 
    ]) 

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
        [tableTitles[7].default],
        [tableTitles[8].default],
        [tableTitles[9].default], 
        [tableTitles[10].default], 
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]], 
        [[]], 
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
    ])

    const [submitReportModal, setSubmitReportModal] = useState(false)

    const reportTypes = [
        {value: 'Weekly 7 Reversal', label: 'Weekly 7 Reversal'},
        {value: 'Void', label: 'Void'},
        {value: 'Rejection', label: 'Rejection'},
        {value: 'Unwind', label: 'Unwind'},
        {value: 'Cancellation', label: 'Cancellation'},
        {value: 'Over / Under', label: 'Over / Under'},
        {value: 'Payback', label: 'Payback'},
        {value: 'Claim', label: 'Claim'},
        {value: '100% Cancellation', label: '100% Cancellation'},
        {value: 'Expense', label: 'Expense'},
        {value: 'Edit Report', label: 'Edit Report'},
    ]

    useEffect(() => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let tempSubagentList = []
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        };
    
        props.tokenSafeAPIRequest('/user/search?limit=99999', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.map((subagent, index) => {
                tempSubagentList.push({value: subagent.id, label: subagent.name})
            })
            setTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Subagent')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (
                              <Dropdown
                                colors={props.colors}
                                onChange={props.onChange}
                                index={props.index}
                                indexParent={props.indexParent}
                                data={tempSubagentList} 
                                width={195}
                                value={props.data}
                              >
                                Test
                              </Dropdown>
                            ),
                        };
                    }
                    return title;
                })
            })
        })
    },[])

    const loadAdminsSubagent = (dealer, y) => {

        console.log(dealer, y)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/dealership/' + dealer + '?fetch_links=True', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempAdminList = []
            result.admin_IDs.map((admin, index) => {
                tempAdminList.push({value: admin.id, label: admin.name})
            })

            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[4]]
                newSubArray[y] = tempAdminList;
                newData[4] = newSubArray;
                return newData
            })
        })
    }

    const loadSubagentsList = (dealerInfo, y) => {
        console.log(dealerInfo)
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/dealership/' + "6655f2b2bc90ba6e6af0a915" + '?fetch_links=true', requestOptions, '', (result) => {
            console.log(result)
            let tempSubagentList = []
            result.user_connections.map((agent, index) => {
                tempSubagentList.push({value: agent.user_ID.id, label: agent.user_ID.first_name + ' ' + agent.user_ID.last_name})
            })
            //tempSubagentList.push({value: '659742ce7c605a59716c4551', label: 'CBA Old Agents'})
            //setAllSubagents(tempSubagentList)

            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[3]]
                newSubArray[y] = tempSubagentList;
                newData[3] = newSubArray;
                return newData
            })
        })
    }

    const loadDealers = (subagentID, y) => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/user/full/' + subagentID + '?fetch_links=true', requestOptions, tokenValues, (result) => {
            console.log(result)

            let tempDealerList = []
            result.dealership_connections.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })

            console.log(tempDealerList)

            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[3]]
                newSubArray[y] = tempDealerList;
                newData[3] = newSubArray;
                return newData
            })
        })
    }

    const loadContractTypes = (dealer, y) => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        console.log(dealer)

        props.tokenSafeAPIRequest('/dealership/' + dealer + '/contract_types', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempContractTypeList = []
            result.VSC_contracts.map((contractType, index) => {
                tempContractTypeList.push({value: {type: 'VSC', subType: contractType}, label: "VSC - " + contractType})
            })
            result.ancillary_contracts.map((contractType, index) => {
                tempContractTypeList.push({value: {type: 'Ancillary', subType: contractType}, label: "Ancillary - " + contractType})
            })
            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[5]]
                newSubArray[y] = tempContractTypeList;
                newData[5] = newSubArray;
                return newData
            })
        })
    }

    return (
        <MainLayout buttonList={buttonList} headerData={headerData} colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} activeButton={true} buttonList={buttonList} title={"Adjustment Reports (OS)"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <div>{/*Just to make sure nothing goes wrong with MainLayout, as this takes the place of MetricHeader*/}</div>
            <Table colors={props.colors} tableData={tableValues} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange}></Table>
            <ModalManager colors={props.colors}>
                <ManageTemplate colors={props.colors} templates={templates} isOpen={manageTemplateModal} onChange={(value, y, x) => console.log(value, x, y)} setIsOpen={() => setManageTemplateModal(false)}></ManageTemplate>
                <SaveTemplate colors={props.colors} isOpen={manageSaveTemplateModal} onChange={(value, y, x) => console.log(value, x, y)} setIsOpen={() => setManageSaveTemplateModal(false)}></SaveTemplate>
                <SubmitReport colors={props.colors} isOpen={submitReportModal} setIsOpen={() => setSubmitReportModal(false)}></SubmitReport>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </MainLayout>
    )
}

export default Main
